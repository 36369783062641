import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addedPointList: {}
}

const addedPointListChange = createSlice({
    name: "addedPointList",
    initialState,
    reducers: {
        addedPointListChangingAction: (state = initialState, action) => {
            const addedPointListChangingState = {
                addedPointList: action.payload,
            };
            return addedPointListChangingState;
        }
    },
})

export const { addedPointListChangingAction } = addedPointListChange.actions;
export default addedPointListChange.reducer;