import React, { Suspense, lazy, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
// Assuming you will use it for logging out

const Signin = lazy(() => import("../components/signin/Signin.jsx"));
const Layout = lazy(() => import("../components/layout/Layout.jsx"));
const Home = lazy(() => import("../components/home/Home.jsx"));
const SpatialQuery = lazy(() =>
  import("../components/spatial-query/SpatialQuery.jsx")
);

const AppRouter = () => {
  // Fetching authentication state from Redux store
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const isLoggedIn = true
  const userDetails = useSelector((state) => state.auth.user); // Optional, if you need user details
  useEffect(() => {
    console.log(isLoggedIn);
  }, [isLoggedIn]);
  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <Suspense fallback={<div>Loading Sign In...</div>}>
          <Signin />
        </Suspense>
      ),
      loader: () => {
        if (isLoggedIn) {
          return redirect("/");
        }
        return null;
      },
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<div>Loading Layout...</div>}>
          {isLoggedIn ? <Layout /> : redirect("/login")}
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<div>Loading Home...</div>}>
              <Home />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
        {
          path: "spatial-query",
          element: (
            <Suspense fallback={<div>Loading Spatial Query...</div>}>
              <SpatialQuery />
            </Suspense>
          ),
          loader: () => {
            if (!isLoggedIn) {
              return redirect("/login");
            }
            return null;
          },
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
