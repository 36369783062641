import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    analysisInfo: null
}

const changeMap = createSlice({
    name: "analysisInfo",
    initialState,
    reducers: {
        changeMapAction: (state = initialState, action) => {
            const mapChangeState = {
                analysisInfo: action.payload,
            };
            return mapChangeState;
        }
    },
})

export const { changeMapAction } = changeMap.actions;
export default changeMap.reducer;