import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    siteSuitablityDetails: {}
}

const siteSuitablityDetailsChange = createSlice({
    name: "siteSuitablityDetails",
    initialState,
    reducers: {
        siteSuitablityDetailsChangingAction: (state = initialState, action) => {
            const siteSuitablityDetailsChangingState = {
                siteSuitablityDetails: action.payload,
            };
            return siteSuitablityDetailsChangingState;
        }
    },
})

export const { siteSuitablityDetailsChangingAction } = siteSuitablityDetailsChange.actions;
export default siteSuitablityDetailsChange.reducer;